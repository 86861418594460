import "./App.css";
// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import TurfDetail from "./Components/TurfDetails/TurfDetail";
import Cart from "./Components/Home/Cart";
// css
import "./Assets/css/separate-css/separate.css";
import config from "./Config";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={`${config.appUrl}`} element={<Home />} />
          <Route
            path={`${config.appUrl}turf-details/:id`}
            element={<TurfDetail />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
