import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faBars,
  faSearch,
  faShoppingBag,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

// logo
import companyLogo from "../../Assets/images/logo.avif";
import config from "../../Config";

const Navbar = () => {
  const [fixedNav, setFixedNav] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const [showMobileInnerLink1, setShowMobileInnerLink1] = useState(false);
  const [showMobileInnerLink2, setShowMobileInnerLink2] = useState(false);

  document.addEventListener("scroll", () => {
    if (window.scrollY >= 47) {
      setFixedNav(true);
    } else {
      setFixedNav(false);
    }
  });

  const data = JSON.parse(localStorage.getItem("items"));

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <header
        className={`bg-white position-relative ${
          fixedNav ? "fixedNavbar" : ""
        }`}
      >
        <div className={`header-text ${fixedNav ? "d-none" : "d-block"}`}>
          <a
            href="#"
            className="text-white"
            aria-label="BOW & ARROW"
          >
            BOW & ARROW - FREE SHIPPING | LIMITED TIME ONLY - CARRIER DISCOUNT
          </a>
        </div>

        <nav className={`container-box navibar`}>
          <div className="menuBtn">
            <FontAwesomeIcon
              icon={faBars}
              className="text-green cross-btn"
              onClick={() => setMobileMenu(true)}
            ></FontAwesomeIcon>
          </div>
          <div className="left-nav">
            <div>
              <Link to={config.appUrl} aria-label="Home link">
                <img src={companyLogo} alt="LawnPride" />
              </Link>
            </div>

            <ul className="navlinks  mb-0">
              <li>
                <a href="#" aria-label="Shop Page">Shop</a>
              </li>
              <li>
                <Link to={`${config.appUrl}`}>Problem Solver</Link>
              </li>
              <li>
                <a href="#">
                  Learning Centre
                </a>
                <ul className="dropdown-links">
                  <li>
                    <a href="#">
                      All Content
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Weeds
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Pests
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Diseases
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Care
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Fertilising
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Renovation
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      New Lawns
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Mowing
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Seasonal Lawn Care
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Care FAQs
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">
                  Lawn Care Programs
                </a>
                <ul className="dropdown-links">
                  <li>
                    <a href="#">
                      All Programs
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Blue Couch Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Buffalo Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Green Couch Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Kikuyu Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Santa Ana Tiftuf Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Zoysia Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Zoysia Premium Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Cool Season Grass Varieties Program
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">
                  Store Locator
                </a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
            </ul>

            {/* mobile navbar ------------------ */}
            <ul className={`mobile-navlinks ${mobileMenu ? "showMenu" : ""}`}>
              <div className="mb-4">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-light-gray cross-btn"
                  onClick={() => setMobileMenu(false)}
                ></FontAwesomeIcon>
              </div>
              <li>
                <a href="# " aria-label="Shop Page">Shop</a>
              </li>
              <li>
                <Link
                  to={`${config.appUrl}`}
                  onClick={() => setMobileMenu(false)}
                >
                  Problem Solver
                </Link>
              </li>
              <li>
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => setShowMobileInnerLink1(!showMobileInnerLink1)}
                >
                  <p>Learning Centre</p>
                  <span className="pe-2">
                    {showMobileInnerLink1 ? "–" : "+"}
                  </span>
                </div>
                <ul
                  className={`mobile-innerLinks ${
                    showMobileInnerLink1 ? "show-inner-links" : ""
                  }`}
                >
                  <li>
                    <a href="#">
                      All Content
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Weeds
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Pests
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Diseases
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Care
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Fertilising
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Renovation
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      New Lawns
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Mowing
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Seasonal Lawn Care
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Lawn Care FAQs
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => setShowMobileInnerLink2(!showMobileInnerLink2)}
                >
                  <p>Lawn Care Programs</p>
                  <span className="pe-2">
                    {showMobileInnerLink2 ? "–" : "+"}
                  </span>
                </div>

                <ul
                  className={`mobile-innerLinks ${
                    showMobileInnerLink2 ? "show-inner-links" : ""
                  }`}
                >
                  <li>
                    <a href="#">
                      All Programs
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Blue Couch Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Buffalo Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Green Couch Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Kikuyu Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Santa Ana Tiftuf Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Zoysia Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Zoysia Premium Program
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Cool Season Grass Varieties Program
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a href="#">
                  Store Locator
                </a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
              <li>
                <a href="#" aria-label="Account">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="me-2 fs-6"
                  ></FontAwesomeIcon>{" "}
                  Account
                </a>
              </li>
            </ul>
            {/* -------------------------------- */}
          </div>

          <div className="navbar-icons">
            <span>
              <a href="#" aria-label="Account Page">
                <FontAwesomeIcon
                  icon={faUser}
                  className="nav-icon user-icon"
                ></FontAwesomeIcon>
              </a>
            </span>
            <span>
              <FontAwesomeIcon
                icon={faSearch}
                className="nav-icon"
                onClick={() => setSearchBar(!searchBar)}
              ></FontAwesomeIcon>
            </span>
          </div>
        </nav>

        <div className={`search-bar ${searchBar ? "search-height" : ""}`}>
          <div className="container-box">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-10 col-9 px-0 position-relative">
                <input
                  type="text"
                  placeholder="Search..."
                  className="search-field"
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="nav-icon"
                  id="search-field-icon"
                ></FontAwesomeIcon>
              </div>

              <div className="col-md-2 col-3 text-md-end text-center px-0">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="nav-icon text-light-gray"
                  onClick={() => setSearchBar(false)}
                ></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
