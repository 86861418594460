import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'


const Footer = () => {
    return (
        <footer className='py-5'>
            <div className="container-box pt-md-4">
                <div className="row">
                    <div className="col-lg-4 col-md-7 mb-lg-0 mb-5 footer-first pe-md-5">
                        <h5 className='text-light-gray'>About LawnPride</h5>
                        <p>
                            Get results with our complete range of professional-grade lawn care products.
                        </p>
                        <p className='mt-4'>
                            From lawn fertilisers, pest control and weed treatments to expert advice on lawn care and equipment, we've got you covered across all things turf.
                        </p>
                        <div>
                            <a href="https://www.facebook.com/lawnprideaustralia"
                            aria-label="Social Media links">
                                <FontAwesomeIcon
                                    icon={faFacebookF}
                                    className="social-icon"
                                ></FontAwesomeIcon>
                            </a>
                            <a href="https://www.instagram.com/lawnprideau/" aria-label="Social Media links">
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    className="social-icon"
                                ></FontAwesomeIcon>
                            </a>
                            <a href="https://www.youtube.com/channel/UCa1xSOxNNl7ThKA6lopp94g" aria-label="Social Media links">
                                <FontAwesomeIcon
                                    icon={faYoutube}
                                    className="social-icon"
                                ></FontAwesomeIcon>
                            </a>
                            <a href="#" aria-label="Social Media links">
                                <FontAwesomeIcon
                                    icon={faTwitter}
                                    className="social-icon"
                                ></FontAwesomeIcon>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-5 mb-lg-0 mb-5">
                        <h5 className='text-light-gray'>INFORMATION</h5>

                        <div className='footer-links'>
                            <a href="https://lawnpride.com.au/pages/shipping-returns" aria-label="Shipping & Returns">Shipping & Returns</a>
                            <a href="https://lawnpride.com.au/pages/about-us" aria-label="About Us"> About Us</a>
                            <a href="https://lawnpride.com.au/pages/privacy-policy" aria-label="Privacy Policy">Privacy Policy</a>
                            <a href="https://lawnpride.com.au/pages/terms-conditions" aria-label="Terms and Conditions">Terms & Conditions</a>
                            <a href="https://lawnpride.com.au/policies/terms-of-service" aria-label="Terms of Setvice">Terms of Service</a>
                            <a href="https://lawnpride.com.au/policies/refund-policy" aria-label="Refund Policy">Refund policy</a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-7 mb-lg-0 mb-5">
                        <h5 className='text-light-gray'>QUICK LINKS</h5>

                        <div className='footer-links'>
                            <a href="https://lawnpride.com.au/pages/contact" aria-label="Contact Us">Contact Us</a>
                            <a href="https://lawnpride.com.au/pages/store-locator" aria-label="Store Locator">Store Locator</a>
                            <a href="https://lawnpride.com.au/pages/lawn-care-faqs" aria-label="FAQ">Lawn Care FAQs</a>
                            <a href="https://lawnpride.com.au/pages/turf-farms-we-recommend" aria-label="Turf Farms We Recommend">Turf Farms We Recommend</a>
                            <a href="https://lawnpride.com.au/pages/product-quick-reference-guide" aria-label="Product Quick Reference">Product Quick Reference Guide</a>
                            <a href="https://lawnpride.com.au/pages/product-sds-labels" aria-label="Product Labels & SDS">Product Labels & SDS</a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-5">
                        <h5 className='text-light-gray'>Newsletter</h5>

                        <div className='subscribe'>
                            <p>
                                Subscribe to receive updates, access to exclusive deals, and more.
                            </p>
                            <form action="">
                                <div className='my-3'>
                                    <input type="text" placeholder='Enter your email address' />
                                </div>
                                <button className='subs-btn'>
                                    SUBSCRIBE
                                </button>
                            </form>
                        </div>
                    </div>

                    <div className="col-12 ms-md-4 text-light-gray text-sm-start text-center" style={{marginTop: '120px'}}>
                        <p className='mb-2 ' style={{fontSize: '13px'}}>&copy; Lawnpride Australia</p>
                        <p className='mb-0 '>ABN: 82 604 752 678</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer