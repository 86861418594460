// reducers.js
const authReducer = (state = { category: null, location: null,turf_type:null }, action) => {
    switch (action.type) {
        case 'SET_CATEGORY':
            return {
                ...state,
                category: action.payload,
            };
        case 'SET_LOCATION':
            return {
                ...state,
                location: action.payload,
            };
        case 'SET_TURFTYPE':
            return {
                ...state,
                turf_type: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;
