import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './Reducers/reducers';

const persistConfig = {
  key: 'root',
  storage:storage ,
};

const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),
    // Add other reducers here
  });
const store = createStore(rootReducer)
const persistor = persistStore(store)

export { store, persistor };
